import React, { useState, useEffect } from "react";
import { DefaultLanguageProps } from "../../interfaces";
import Anerka from "../../assets/images/anerka.jpg";
import { ACTIVE_MEDIA_URL, ACTIVE_URL } from "../../constants";

const AnerkaPage: React.FC<DefaultLanguageProps> = ({ activeLanguage }) => {

    return (
        <>
            <div className="bg-anerkaBg">
                <div className="max-w-1200 p-[3%] pt-[120px] flex m-auto">
                    <div className="w-1/2 flex items-center justify-center relative">

                        <img src={Anerka} alt="" className="max-h-[80vh] max-w-[80%] border border-neutral-700 shadow-xl z-10" />
                        <div className="absolute bg-anerkaRed w-1/6 h-[6px] bottom-[30%] left-0 "></div>
                    </div>

                    <div className="flex items-start justify-center w-1/2 gap-8 flex-col">
                        <h2 className="text-4xl">Ανέρκα - Τα πρόσωπα του πάγου</h2>
                        <p className="max-w-4/5 text-lg">
                            Εικόνες κρυμμένες στις αρθρώσεις, ήχοι που δεν προφταίνουν πάντοτε να γίνουν λόγος, μουσική που βγαίνει από μια βαθιά ανάγκη σιωπής. Το σώμα βυθίζεται στον εαυτό του και ξαναβγαίνει στην επιφάνεια, στρέφεται και επικοινωνεί.
                            Κίνηση. Παύση. Ρυθμός. Σύνθεση.
                            Οι ιστορίες υπήρχαν πάντοτε εκεί, έτοιμες να ξεπηδήσουν απ' τις ρωγμές του χρόνου.
                        </p>
                        <a href="https://www.govostis.gr/product/26826/anerka.html" target="_blank" className="cursor-pointer text-lg">... <span className="underline">βρείτε το εδώ</span></a>
                    </div>
                </div>
            </div>
            <div className="py-16 bg-neutral-50">
                <div className="max-w-1200 m-auto flex justify-center items-center pb-16 px-4 xl:px-0">
                    <h3 className="text-4xl">Σχετικές Δημοσιεύσεις</h3>
                </div>
                <div className="max-w-1200 m-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 px-4 xl:px-0">
                    <a href='https://www.poiein.gr/2023/06/22/' target='_blank' className="flex flex-col gap-2 w-full cursor-pointer">
                        <div className="border border-neutral-300 rounded py-4 bg-white flex items-center justify-center h-48 shadow-md">
                            <img src='https://www.poiein.gr/wp-content/uploads/2018/09/logotypo-poiein260-1.jpg' className="h-32" />
                        </div>
                        <h4 className="text-xl">Συνέντευξη στο περιοδικό Ποιείν</h4>
                    </a>
                    <a href='https://www.fractalart.gr/varvara-spyropoyloy-glossologia/' target='_blank' className=" flex flex-col gap-2 w-full cursor-pointer">
                        <div className="border border-neutral-300 bg-white rounded py-4 flex items-center justify-center h-48 shadow-md">
                            <img src='https://www.fractalart.gr/wp-content/uploads/2020/11/logo_fractal_1.jpg' className="h-28" />
                        </div>
                        <h4 className="text-xl">Δημοσίευση στο περιοδικό FRACTAL</h4>
                    </a>

                    <a href='https://jasmin.bg/2019/pero/esen-maslinovoto-drvo-i-opit-tri-stihotvoreniya-ot-varvara-spiropulu/' target='_blank' className="flex flex-col gap-2 w-full cursor-pointer">
                        <div className="border border-neutral-300 rounded py-4 flex bg-white items-center justify-center h-48 shadow-md">
                            <img src='https://jasmin.bg/wp-content/themes/Jasmin26/assets/i/logo.svg' className="h-24" />
                        </div>
                        <h4 className="text-xl">Δημοσίευση στο περιοδικό Jasmin</h4>
                    </a>
                </div>
            </div>
            <div className="py-16 bg-neutral-50">
                <div className="max-w-1200 m-auto flex justify-center items-center pb-16 px-4 xl:px-0">
                    <h3 className="text-4xl">Ανάγνωση Ποιημάτων</h3>
                </div>
                <div className="max-w-1200 w-full m-auto px-4 xl:px-0">
                <iframe src="https://player.vimeo.com/video/940226809?h=79d6e3e6c3" className="w-full max-h-[670px] h-screen bg-neutral-200 rounded-md" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe>

                </div>
            </div>
        </>
    )

}


export default AnerkaPage;