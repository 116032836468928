import React, { useState } from 'react';
import { DefaultLanguageProps } from '../../interfaces';
import './PoetryPage.css';
import { Link } from 'react-router-dom';
import greekLanguage from '../../locales/gr.json';
import englishLanguage from '../../locales/en.json';
import { ACTIVE_MEDIA_URL, ACTIVE_URL } from '../../constants';
import HTMLRenderer from '../../utils/HTMLRenderer';

type Poem = {
    title: string;
    background_color: string;
    poem: string;
    slug: string;
    image: string;
    video: string;
    display_type: string;
    show_title: boolean;
}

const PoetryPage: React.FC<DefaultLanguageProps> = ({ activeLanguage }) => {
    const [mode, setMode] = useState<'intro' | 'poem'>('intro');

    const [poem, setPoem] = useState<Poem | null>(null);

    const [src, setSrc] = useState<string>('');

    const languageData = activeLanguage === 'GR' ? greekLanguage : englishLanguage;

    const updatePoem = () => {
        fetch(`${ACTIVE_URL}/api/poetry/api/get_random_poem/`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(response => response.json())
            .then(data => {
                setPoem(data);
                console.log(data);
                if (data.display_type == 'Video') {
                    if (data.title == 'Ανέρκα') {
                        setSrc('https://www.youtube.com/embed/pe_cT8517c0?si=DbUrNQsgW-yshq4G');
                    }
                    if (data.title == 'Τα πρόσωπα του πάγου') {
                        setSrc('https://player.vimeo.com/video/940223202?h=876e27b85b');
                    }
                }
                setMode('poem');
            })
            .catch(error => console.log(error));
    }



    return (
        <>
            {
                mode === 'intro'
                    ?
                    (<div className='poetry-page h-screen select-none'>
                        <div className='flex flex-col items-center justify-center max-w-[800px] text-white h-full text-center m-auto'>
                            <h1 className='text-3xl mb-10'>{languageData.poetry.title}</h1>
                            <div className='text-xl mb-5'>
                                {languageData.poetry.text}
                            </div>
                            <button onClick={updatePoem} className='h-32 w-32 rounded-full bg-black bg-opacity-50 flex items-center justify-center cursor-pointer'>
                                {languageData.poetry.read_more}
                            </button>
                        </div>
                    </div>)
                    :
                    (
                        <div className='min-h-screen select-none pt-20'>
                            {
                                poem && poem.display_type == 'Image' && (
                                    <div className=' h-full max-w-1200 m-auto'>
                                        <div className='w-full flex items-center justify-center pb-14'>
                                            <img src={`${ACTIVE_MEDIA_URL + '../' + poem.image}`} alt="" />
                                        </div>
                                        <div className='flex flex-col gap-4 items-center pb-14'>
                                            <h2 className='text-3xl '>{poem.title}</h2>
                                            <p className='text-lg'>
                                                <HTMLRenderer htmlContent={poem.poem} />
                                            </p>
                                        </div>
                                    </div>
                                )
                            }
                            {
                                poem && poem.display_type == 'Video' && (
                                    <div className=' h-full max-w-1200 m-auto'>
                                        <div className='w-full flex items-center justify-center pb-14'>
                                            <iframe
                                                src={src}
                                                className='w-full rounded-md min-h-[420px] bg-neutral-300'
                                                
                                                allow="autoplay; fullscreen; picture-in-picture"
                                                allowFullScreen
                                            ></iframe>
                                        </div>
                                        <div className='flex flex-col gap-4 items-center pb-14'>
                                            <h2 className='text-3xl '>{poem.title}</h2>
                                            <p className='text-lg'>
                                                <HTMLRenderer htmlContent={poem.poem} className='text-center' />
                                            </p>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    )
            }
        </>
    )
}

export default PoetryPage;