import React, { useState, useEffect } from "react";
import { TranslationType } from "../../../interfaces";
import { Link } from "react-router-dom";
import { ACTIVE_MEDIA_URL } from "../../../constants";

const TranslationItem:React.FC<TranslationType> = ({ id, title_gr, desc_gr, translator, image_url, bibl_gr, slug }) => {

    return (
        <Link to={`/translation/${slug}`} className="">
            <div className="w-full w-[390px] h-[390px] max-w-[90vw] max-h-[70vh] bg-tranlsationItemCardBackGround rounded-sm flex flex-col justify-center items-center">
                <img src={ACTIVE_MEDIA_URL + image_url} alt={title_gr} className="max-h-4/5 max-w-4/5 h-4/5 shadow-lg" />
            </div>
            <div>{title_gr}</div>
        </Link>
    )
}

export default TranslationItem;