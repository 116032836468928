import React from 'react';
import  './innerHtml.css';
interface HTMLRendererProps {
  htmlContent: string;
  className?: string;
}

const HTMLRenderer: React.FC<HTMLRendererProps> = ({ htmlContent, className='' }) => {
  return (
    <div className={`leading-7 inner-html ${className}`} dangerouslySetInnerHTML={{ __html: htmlContent }} />
  );
};

export default HTMLRenderer;