import React, { useState } from 'react';
import { DefaultLanguageProps } from '../../interfaces';
import AboutImg from '../../assets/images/about/about_img.jpg'
import greekLanguage from '../../locales/gr.json';
import englishLanguage from '../../locales/en.json';

const AboutPage: React.FC<DefaultLanguageProps> = ({ activeLanguage }) => {
    const languageData = activeLanguage === 'GR' ? greekLanguage : englishLanguage;
    return (
        <div className='md:h-screen bg-aboutCyan flex flex-col md:flex-row pt-20 pb-8 md:pt-0 md:pb-0 justify-center gap-8 items-center'>
            <h3 className='md:hidden text-5xl'>
                {languageData.about.title}
            </h3>
            <div className='p-0 h-full flex items-center justify-center md:max-w-1/3 md:w-1/3 px-10'>
                <img src={AboutImg} alt="Barbara Spyropoulou Image" className='max-h-[70vh]' />
            </div>
            <div className='max-w-2/3 w-2/3'>
                <div className='flex flex-col gap-8 justify-center items-start'>
                    <h3 className='hidden md:block text-5xl'>
                        {languageData.about.title}
                    </h3>
                    <p className='w-5/6 text-xl'>
                        {languageData.about.text}
                    </p>
                </div>

            </div>
        </div>
    );
}

export default AboutPage;