import React, { useEffect, useState } from "react";
import { DefaultLanguageProps } from "../../interfaces";
import './ProjectsPage.css';
import { Link } from "react-router-dom";
import { ACTIVE_URL } from "../../constants";
import greekLanguage from '../../locales/gr.json';
import englishLanguage from '../../locales/en.json';

const ProjectsPage: React.FC<DefaultLanguageProps> = ({ activeLanguage }) => {
    const languageData = activeLanguage === 'GR' ? greekLanguage : englishLanguage;
    const [nextProjectLink, setNextProjectLink] = useState<string>('');

    useEffect(() => {
        const fetchResponses = async () => {
            try {
                const response = await fetch(`${ACTIVE_URL}/api/projects/api/getAProjectItem`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    }
                });
                if (response.ok) {
                    const data = await response.json();
                    setNextProjectLink(data[0].slug);

                } else {
                    console.error('Failed to fetch responses');
                }
            } catch (error) {
                console.error('Error fetching responses:', error);
            }
        }
        fetchResponses();
    }, []);

    return (
        <div className="h-screen projects-page">
            
            <div className='flex flex-col items-center justify-center max-w-[800px] text-white h-full text-center m-auto'>
                <h1 className='text-7xl mb-10 text-neutral-300'>{languageData.projects.title}</h1>
                {/* <div className='text-xl mb-5'>
                    Η ποίηση δεν είναι λόγος — για την ακρίβεια, ο λόγος έπεται. Η ποίηση είναι πρωτίστως ματιά πάνω στον κόσμο, μέσα μας και έξω από εμάς. Η ποιητική ματιά προηγείται και, υπό αυτή την έννοια, η ποίηση είναι μια τέχνη των αισθήσεων, όλων των αισθήσεων, που συμπυκνώνονται στην καλλιέργεια ενός ποιητικού βλέμματος για τον κόσμο.
                </div> */}
                <Link to={`/project/${nextProjectLink}`}  className='h-32 w-32 rounded-full text-neutral-300 bg-black bg-opacity-50 flex items-center justify-center cursor-pointer'>
                    {languageData.projects.read_more}
                </Link>
            </div>
        </div>
    )

}

export default ProjectsPage;