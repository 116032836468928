import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { DefaultLanguageProps } from "../../interfaces";
import { ACTIVE_MEDIA_URL, ACTIVE_URL } from "../../constants";
import { IonIcon } from "@ionic/react";
import HTMLRenderer from "../../utils/HTMLRenderer";

type ProjectType = {
    id: string;
    author: string;
    background_color: string;
    content: string;
    image: string;
    image_width: string;
    photo_complimentary_text: string;
    text_complimentary_text: string;
    title: string;
    // text_color_hex: string;
    text_background_color_hex: string;
    text_complementary_color: string;
    photo_complementary_color: string;
    rand_image_positioning: string;
    next_project_url: string;
    text_color_hex: string;
}

const ProjectSinglePage: React.FC<DefaultLanguageProps> = ({ activeLanguage }) => {
    const { slug } = useParams();

    const [project, setProject] = useState<ProjectType>({} as ProjectType);

    const [isPhotoHovered, setIsPhotoHovered] = useState(false);
    const [isTextHovered, setIsTextHovered] = useState(false);

    const text_background = {
        background: project.text_background_color_hex,
        width: 100 - parseInt(project.image_width) + 'vw',
        color: project.text_color_hex,
    };

    const text_color = {
        color: project.text_color_hex,
    };

    const image__background = {
        backgroundImage: `url(${ACTIVE_MEDIA_URL + project.image})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        width: project.image_width + 'vw',
    };

    useEffect(() => {
        const fetchResponses = async () => {
            try {
                const response = await fetch(`${ACTIVE_URL}/api/projects/api/getProjectItem/${slug}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    }
                });
                if (response.ok) {
                    const data = await response.json();
                    console.log(data)
                    setProject(data);
                } else {
                    console.error('Failed to fetch responses');
                }
            } catch (error) {
                console.error('Error fetching responses:', error);
            }
        }
        fetchResponses();
    }, [slug]);

    return (
        <div className="h-screen">
            {project.rand_image_positioning === 'left' ?
                (
                    <div className="flex items-center justify-center  h-screen w-screen">
                        <div className={`w-[${project.image_width}vw] h-full relative`} onMouseEnter={() => setIsPhotoHovered(true)} onMouseLeave={() => setIsPhotoHovered(false)} style={image__background}>
                            <div className={`transition-opacity duration-200 ${isPhotoHovered ? 'opacity-100' : 'opacity-0'}  absolute bottom-1 right-3 text-sm`} style={text_color}>
                                {project.photo_complimentary_text}
                            </div>
                        </div>
                        <div className={`h-full flex items-center justify-center relative`} onMouseLeave={() => setIsTextHovered(false)} onMouseOver={() => setIsTextHovered(true)} style={text_background}>
                            <HTMLRenderer htmlContent={project.content} className='text-xl max-w-[80%] ' />
                            <div className={`transition-opacity duration-200 ${isTextHovered ? 'opacity-100' : 'opacity-0'} absolute bottom-1 left-3 text-sm`} style={text_color}>
                                {project.text_complimentary_text}
                            </div>
                        </div>
                    </div>
                )
                : (
                    <div className="flex items-center justify-center h-screen">
                        <div className={`h-full flex items-center justify-center relative`} onMouseLeave={() => setIsTextHovered(false)} onMouseOver={() => setIsTextHovered(true)} style={text_background}>
                            <HTMLRenderer htmlContent={project.content} className='text-xl max-w-[80%] '  />
                            <div className={`transition-opacity duration-200 ${isTextHovered ? 'opacity-100' : 'opacity-0'}  absolute bottom-1 right-3 text-sm`} style={text_color}>
                                {project.text_complimentary_text}
                            </div>
                        </div>
                        <div className={`w-[${project.image_width}vw] h-full relative`} onMouseEnter={() => setIsPhotoHovered(true)} onMouseLeave={() => setIsPhotoHovered(false)} style={image__background}>
                            <div className={`transition-opacity duration-200 ${isPhotoHovered ? 'opacity-100' : 'opacity-0'}  absolute bottom-1 left-3 text-sm bg-[${project.text_background_color_hex}]`} style={text_color}>
                                {project.photo_complimentary_text}
                            </div>
                        </div>
                    </div>
                )
            }
            <Link to={`/project/${project.next_project_url}`} className='h-[calc(100%-60px)] fixed top-[60px] right-0 top-0 w-12 go-next-grad text-white  flex items-center justify-center cursor-pointer'>
                <IonIcon icon='chevron-forward' className="text-4xl ml-2" />
            </Link>
        </div>
    );
}

export default ProjectSinglePage;