import React from 'react';
import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import HomePage from './Pages/HomePage/HomePage';
import AboutPage from './Pages/AboutPage/AboutPage';
import TranslationsPage from './Pages/TranslationsPage/TranslationsPage';
import TranslationSinglePage from './Pages/TranslationSinglePage/TranslationSinglePage';
import PoetryPage from './Pages/PoetryPage/PoetryPage';
import AnerkaPage from './Pages/AnerkaPage/AnerkaPage';
import ProjectsPage from './Pages/ProjectsPage/ProjectsPage';
import ProjectSinglePage from './Pages/ProjectSinglePage/ProjectSinglePage';
import PerformancePage from './Pages/PerformancePage/PerformancePage';

import Navbar from './Components/Navbar/Navbar';


function App() {
  const [activeLanguage, setActiveLanguage] = React.useState('EN');


  return (
    <BrowserRouter>
      <Navbar activeLanguage={activeLanguage} changeLanguage={(lang) => setActiveLanguage(lang)} />
      <Routes>
        <Route path="/" element={<HomePage activeLanguage={activeLanguage} />} />
        <Route path="/about" element={<AboutPage activeLanguage={activeLanguage} />} />
        <Route path="/translations" element={<TranslationsPage activeLanguage={activeLanguage} />} />
        <Route path="/translation/:slug" element={<TranslationSinglePage activeLanguage={activeLanguage} />} />
        <Route path="/poetry" element={<PoetryPage activeLanguage={activeLanguage} />} />
        <Route path="/anerka" element={<AnerkaPage activeLanguage={activeLanguage} />} />
        <Route path="/projects" element={<ProjectsPage activeLanguage={activeLanguage} />} />
        <Route path="/project/:slug" element={<ProjectSinglePage activeLanguage={activeLanguage} />} />
        <Route path="/performance" element={<PerformancePage activeLanguage={activeLanguage} />} />
      </Routes>

    </BrowserRouter>
  );
}

export default App;
