import React, { useState, useEffect, useRef } from "react";
import { DefaultLanguageProps, TranslationType } from "../../interfaces";
import { ACTIVE_URL } from "../../constants";
import TranslationItem from "./TranslationItem/TranslationItem";
import greekLanguage from '../../locales/gr.json';
import englishLanguage from '../../locales/en.json';

const TranslationsPage: React.FC<DefaultLanguageProps> = ({ activeLanguage }) => {
    const languageData = activeLanguage === 'GR' ? greekLanguage : englishLanguage;
    const [translations, setTranslations] = useState<TranslationType[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const fetched = useRef<boolean>(false);


    useEffect(() => {
        const fetchResponses = async () => {
            try {
                const response = await fetch(`${ACTIVE_URL}/api/translations/api/`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    }
                });
                if (response.ok) {
                    const data = await response.json();
                    setTranslations([])
                    let temp_arr: any = [];
                    data.map((item: any) => {
                        if (item.slug in temp_arr) return;
                        setTranslations((prev) => [...prev, {
                            id: item.id,
                            title_gr: item.title_gr,
                            desc_gr: item.description,
                            translator: item.translator,
                            image_url: item.book_image,
                            bibl_gr: item.bibl,
                            slug: item.slug
                        }]);
                        temp_arr.push(item.slug);
                    })
                    setLoading(false);
                } else {
                    console.error('Failed to fetch responses');
                }
            } catch (error) {
                console.error('Error fetching responses:', error);
            }
        }
        // Cleanup function
        if (!fetched.current) {
            fetchResponses();
            fetched.current = true;
        }
    }, []);

    return (
        <div className="min-h-screen bg-translationBlue py-24 text-white ">
            <div className="w-full max-w-1200 mx-auto relative">
                <h1 className="text-4xl">{languageData.translations.title}</h1>
            </div>
            <div className="h-[1px] bg-neutral-700 w-screen my-2"></div>
            <div className="w-full max-w-1200 mx-auto relative">
                <div className="grid grid-cols-3 gap-x-2 gap-y-4">
                    {
                        loading ? <div className="w-full flex justify-center items-center">
                            <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-neutral-700"></div>
                        </div> 
                        : 
                        translations.map((item) => {
                            return (
                                <TranslationItem key={item.id} id={item.id} title_gr={item.title_gr} desc_gr={item.desc_gr} translator={item.translator} image_url={item.image_url} bibl_gr={item.bibl_gr} slug={item.slug} />
                            )
                        })
                    }
                </div>
            </div>

        </div>
    );

}

export default TranslationsPage;