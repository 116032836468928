import React, { useState, } from 'react';
import { Link } from 'react-router-dom';
import { LanguageChangerProps } from '../../interfaces';
import { IonIcon } from '@ionic/react';
import greekLanguage from '../../locales/gr.json';
import englishLanguage from '../../locales/en.json';

const Navbar: React.FC<LanguageChangerProps> = ({ activeLanguage, changeLanguage }) => {
    const languageData = activeLanguage === 'GR' ? greekLanguage : englishLanguage;
    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    }
    const handleMenuItemClick = () => {
        setMenuOpen(false);
        window.scrollTo(0, 0);
    };


    return (
        <div className={`${menuOpen ? 'h-screen items-start py-4 ' : 'h-[60px] items-center'}  z-20 gap-5 text-lg text-white justify-center fixed w-screen flex m-auto backdrop-blur z-10 bg-black bg-opacity-50 select-none`}>
            <div className='cursor-pointer md:hidden z-10' onClick={toggleMenu}>
                Menu
            </div>
            <div className={`${menuOpen ? 'absolute h-screen w-screen flex flex-col items-center justify-center text-xl z-0 max-h-screen ' : 'max-h-0 hidden'}  md:flex md:max-h-max gap-5 `}>
                <Link to='/' className='hover:border-b-2 hover:border-b-white duration-100' onClick={handleMenuItemClick}>{languageData.menu.home}</Link>
                <Link to='/poetry' className='hover:border-b-2 hover:border-b-white duration-100' onClick={handleMenuItemClick}>{languageData.menu.poetry}</Link>
                <Link to='/anerka' className='hover:border-b-2 hover:border-b-white duration-100' onClick={handleMenuItemClick}>{languageData.menu.anerka}</Link>
                <Link to='/translations' className='hover:border-b-2 hover:border-b-white duration-100' onClick={handleMenuItemClick}>{languageData.menu.translations}</Link>
                <Link to='/projects' className='hover:border-b-2 hover:border-b-white duration-100' onClick={handleMenuItemClick}>{languageData.menu.projects}</Link>
                <Link to='/performance' className='hover:border-b-2 hover:border-b-white duration-100' onClick={handleMenuItemClick}>{languageData.menu.performance}</Link>
                <Link to='/about' className='hover:border-b-2 hover:border-b-white duration-100' onClick={handleMenuItemClick}>{languageData.menu.about}</Link>
            </div>

            <div className='absolute right-10 text-[0.9rem] flex items-center justify-center gap-1 cursor-pointer' onClick={() => changeLanguage(activeLanguage == 'EN' ? 'GR' : 'EN')} >
                <IonIcon icon='earth' className='cursor-pointer mt-0.5' />
                <div>
                    {activeLanguage == 'EN' ? 'en' : 'ελ'}
                </div>
            </div>
        </div>
    )
}

export default Navbar;