import React, { useEffect, useState } from "react";
import { DefaultLanguageProps } from "../../interfaces";
import './PerformancePage.css';
import kav_main from '../../assets/images/performance_main.jpg'
import per1 from '../../assets/images/performance/per1.jpg'
import per2 from '../../assets/images/performance/per2.jpg'
import per3 from '../../assets/images/performance/per3.jpg'
import per4 from '../../assets/images/performance/per4.jpg'
import per5 from '../../assets/images/performance/per5.jpg'
import per6 from '../../assets/images/performance/per6.jpg'
import greekLanguage from '../../locales/gr.json';
import englishLanguage from '../../locales/en.json';

const PerformancePage: React.FC<DefaultLanguageProps> = ({ activeLanguage }) => {
    const languageData = activeLanguage === 'GR' ? greekLanguage : englishLanguage;
    return (
        <div>
            <div className="h-[90vh] flex items-center justify-center performance-page select-none px-4 xl:px-0">
                <div className="flex flex-col gap-3 text-white items-center justify-centers text-neutral-100">
                    <h4 className="text-lg tracking-[0.75rem]">performance</h4>
                    <h2 className="text-5xl text-center">Embodying Meaning, Uniting Perception.</h2>
                </div>

            </div>
            <div className="h-auto bg-neutral-200">
                <div className="grid grid-cols-1 md:grid-cols-2 max-w-1200 m-auto py-10 md:py-20 gap-8 px-4 xl:px-0">
                    <div className="flex items-center justify-center w-full w-1/2">
                        <img src={kav_main} alt="Barbara Spyropoulo Performance" className="rounded-md md:rounded-sm" />
                    </div>
                    <div className="flex items-start justify-center w-full w-1/2 flex-col gap-6 text-neutral-800">
                        <p>
                            {languageData.performance.main}
                        </p>
                    </div>
                </div>
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 max-w-1200 py-10 place-items-center m-auto gap-4  px-4 xl:px-0">
                    <div className="w-full w-[390px] h-[390px] max-w-[90vw] max-h-[70vh] bg-neutral-300 rounded-sm flex flex-col justify-center items-center hover:scale-105 duration-200">
                        <img src={per3} alt='' className="max-h-4/5 max-w-4/5 w-5/6 shadow-lg" />
                    </div>
                    <div className="w-full w-[390px] h-[390px] max-w-[90vw] max-h-[70vh] bg-neutral-300 rounded-sm flex flex-col justify-center items-center hover:scale-105 duration-200">
                        <img src={per5} alt='' className="max-h-4/5 max-w-4/5 w-5/6 shadow-lg" />
                    </div>
                    <div className="w-full w-[390px] h-[390px] max-w-[90vw] max-h-[70vh] bg-neutral-300 rounded-sm flex flex-col justify-center items-center hover:scale-105 duration-200">
                        <img src={per4} alt='' className="max-h-4/5 max-w-4/5 w-5/6 shadow-lg" />
                    </div>
                    <div className="w-full w-[390px] h-[390px] max-w-[90vw] max-h-[70vh] bg-neutral-300 rounded-sm flex flex-col justify-center items-center hover:scale-105 duration-200">
                        <img src={per1} alt='' className="max-h-4/5 max-w-4/5 w-5/6 shadow-lg" />
                    </div>
                    <div className="w-full w-[390px] h-[390px] max-w-[90vw] max-h-[70vh] bg-neutral-300 rounded-sm flex flex-col justify-center items-center hover:scale-105 duration-200">
                        <img src={per2} alt='' className="max-h-4/5 max-w-4/5 h-4/5 shadow-lg" />
                    </div>
                    <div className="w-full w-[390px] h-[390px] max-w-[90vw] max-h-[70vh] bg-neutral-300 rounded-sm flex flex-col justify-center items-center hover:scale-105 duration-200">
                        <img src={per6} alt='' className="max-h-4/5 max-w-4/5 h-4/5 shadow-lg" />
                    </div>

                </div>
            </div>
        </div>
    )
}

export default PerformancePage;