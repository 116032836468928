import React, { useState, useEffect, useRef } from 'react';
import { DefaultLanguageProps, TranslationType } from '../../interfaces';
import { useParams } from 'react-router-dom';
import { ACTIVE_URL, ACTIVE_MEDIA_URL } from '../../constants';
import { IonIcon } from "@ionic/react";

const TranslationSinglePage:React.FC<DefaultLanguageProps> = ({activeLanguage}) => {
    const { slug } = useParams();
    const [translation, setTranslation] = useState<TranslationType>({} as TranslationType);
    const [isReadMoreOpen, setIsReadMoreOpen] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const fetched = useRef<boolean>(false);

    useEffect(() => {
        const fetchResponses = async () => {
            try {
                const response = await fetch(`${ACTIVE_URL}/api/translations/api/${slug}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    }
                });
                if (response.ok) {
                    const data = await response.json();
                    setTranslation({} as TranslationType);
                    setTranslation({
                      id: data.id,
                        title_gr: data.title_gr,
                        desc_gr: data.description_gr,
                        translator: data.translator,
                        image_url: data.book_image,
                        bibl_gr: data.bibliogr,
                        slug: data.slu  
                    })  
                    setLoading(false);
                } else {
                    console.error('Failed to fetch responses');
                }
            } catch (error) {
                console.error('Error fetching responses:', error);
            }
        }
        // Cleanup function
        if (!fetched.current) {
            fetchResponses();
            fetched.current = true;
        }
    }, []);

    return (
        <div className='h-screen flex items-center justify-center gap-10 max-w-1200 m-auto select-none'>
            <div className='w-1/3'>
                <img src={ACTIVE_MEDIA_URL + translation.image_url} alt="" />
            </div>
            <div className='w-2/3 flex flex-col gap-5'>
                <h2 className='text-5xl text-neutral-800'>{translation.title_gr}</h2>
                <p className='text-neutral-700 text-lg'>{translation.desc_gr}</p>
                <div className='w-full'>
                    <div className='inline-flex items-center gap-2 border-b border-b-neutral-500 cursor-pointer' onClick={() => setIsReadMoreOpen(!isReadMoreOpen)}>
                        <p className='text-lg'>Περισσότερες πληροφορίες</p>
                        <IonIcon icon={isReadMoreOpen ? 'chevron-up' : 'chevron-down'} />
                    </div>
                    <div>
                        {isReadMoreOpen && <p className='text-neutral-700 mt-2'>{translation.bibl_gr}</p>}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TranslationSinglePage;