import React, { useEffect, useState, useRef } from 'react';
import { DefaultLanguageProps } from '../../interfaces';
import HomeImage from '../../assets/images/home/home_bg.jpg';
import './HomePage.css';
import { Link } from 'react-router-dom';
import greekLanguage from '../../locales/gr.json';
import englishLanguage from '../../locales/en.json';

const HomePage: React.FC<DefaultLanguageProps> = ({ activeLanguage }) => {
    const languageData = activeLanguage === 'GR' ? greekLanguage : englishLanguage;
    return (
        <div className='h-screen flex items-center justify-center select-none'>
            <div className='home-bg-img w-full sm:w-[70%] h-full flex items-center justify-center'>
                <div className='sm:hidden flex flex-col text-white gap-4 text-2xl'>
                    <Link to='/poetry'>{languageData.menu.poetry}</Link>
                    <Link to='/anerka'>{languageData.menu.anerka}</Link>
                    <Link to='/translations'>{languageData.menu.translations}</Link>
                    <Link to='/projects'>{languageData.menu.projects}</Link>
                    <Link to='/performance'>{languageData.menu.performance}</Link>
                    <Link to='/about'>{languageData.menu.about}</Link>
                    <div className='absolute bottom-2 left-0 w-full text-center text-base'>
                        Barbara Spyropoulou &copy; {new Date().getFullYear()}
                    </div>
                </div>

            </div>
            <div className='hidden sm:flex w-[30%] h-full flex-col items-center justify-center text-2xl gap-8'>
                <Link to='/poetry'>{languageData.menu.poetry}</Link>
                <Link to='/anerka'>{languageData.menu.anerka}</Link>
                <Link to='/translations'>{languageData.menu.translations}</Link>
                <Link to='/projects'>{languageData.menu.projects}</Link>
                <Link to='/performance'>{languageData.menu.performance}</Link>
                <Link to='/about'>{languageData.menu.about}</Link>
            </div>
            <div className='hidden sm:blockabsolute bottom-0 left-[70vw] w-[30vw] text-center text-lg'>
                Barbara Spyropoulou &copy; {new Date().getFullYear()}
            </div>
        </div>
    )
}

export default HomePage;